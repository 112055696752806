<template>
    <div ref="popupModal" class="modal fade" data-backdrop tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ $t('coin_treasure.cunbibao_service',{platform:sysconfig.name}) }}</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col">
                            <loading-indicator v-if="!body" />
                            <pre class="agreement rtl" v-html="body"></pre>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            locale: null,
            body: null,

            // The popup confirmation modal.
            popupModal: null
        };
    },

    mounted() {
        this.popupModal = new Modal(this.$refs.popupModal);
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        showModal() {
            this.popupModal.show();

            if (!this.body || this.locale != this.$i18n.locale) {
                this.body = null;
                this.getHelpContentAsync();
            }
        },

        async getHelpContentAsync() {
            const locale = this.$i18n.locale;
            const req_url = '/api/v1/help?id=' + encodeURIComponent('saving') + '&lang=' + encodeURIComponent(locale);
            const json = await $.callGetApi(this, req_url);
            if (json) {
                if (json.errcode === 0 && json.data) {
                    this.locale = locale;
                    this.body = json.data;
                } else {
                    this.locale = locale;
                    this.body = this.$t('general.no_data');
                }
            }
        },
    }
};
</script>