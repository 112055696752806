<template>
    <div class="page">
        <div class="earn-main ">
            <div class="top earn-top-bg">
                <div class="content">
                    <div class="title">
                        <h2 class="h2">{{ $t(regionT) }}</h2>
                        <div class="p">{{ $t('coin_treasure.flexible_tip') }}</div>
                    </div>
                    <div class="right">
                        <div class="earn">
                            <div class="eyes"></div>
                            <div class="value">
                                <p class="p">{{ $t('coin_treasure.holding_assets') }}</p>
                                <div class="text">
                                    <div>≈ {{ amountValue }}USDT</div>
                                    <!-- <span class="">≈ $ --</span> -->
                                </div>
                                <div class="link">
                                    <router-link to="/saving/account" class="btn btn-primary route-btn-primary">{{ $t('coin_treasure.account') }}</router-link>
                                </div>
                            </div>
                            <div class="total">
                                <p class="p">{{ $t('coin_treasure.cumulative_income') }}</p>
                                <div class="text">
                                    <div>≈ {{ interestValue }}USDT</div>
                                    <!-- <span class="">≈ $ --</span> -->
                                </div>
                                <div class="link">
                                    <router-link to="/saving/history" class="btn btn-primary route-btn-primary">{{ $t('coin_treasure.history') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="earn-table">
                <div class="title">
                    {{ $t('coin_treasure.capital_investment') }}
                    <span class="sm">{{ $t('coin_treasure.deposit_tip') }}</span>
                    <!-- <div class="search-box">
                    <div class="input-group">
                        <input type="text" class="form-control input" placeholder="Search for...">
                        <span class="input-group-btn">
                            <button class="btn btn-default" type="button">Go!</button>
                        </span>
                    </div>
                </div> -->
                </div>
                <div class="list">
                    <table class="table">
                        <colgroup>
                            <col style="width: 17%;">
                            <col style="width: 18%;">
                            <col style="width: 50%;">
                            <col style="width: 22%;">
                        </colgroup>
                        <thead class="head">
                            <tr>
                                <th class="nowrap"><div>{{ $t('coin_treasure.currency') }}</div></th>
                                <th class="nowrap">{{ $t('coin_treasure.APR') }}</th>
                                <th class="nowrap">{{ $t('coin_treasure.term_days') }}</th>
                                <th class="nowrap"></th>
                            </tr>
                        </thead>
                        <tbody class="body" v-if="list.length > 0">
                            <tr v-for="(item, key) in list" :key="key">
                                <td>
                                    <!-- <Coin class="coin" :name="item.token" /> -->
                                    {{ item.token }}
                                </td>
                                <td>
                                    <span :class="item.interestRatesList[0].value > 0 ? 'up' : 'down'">{{ Math.round(item.value * 10000) / 100 }}%</span>
                                </td>
                                <td>
                                    <div class="day-list">
                                        <div v-for="(i, k) in item.interestRatesList" :key="k" class="day-li" :class="{ 'active': i.active === true }" @click="selectDay(key, k)">
                                            <span class="text">{{ i.day == '0' ? $t('coin_treasure.current') : i.day }}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <button v-if="item.status === 1" class="btn btn-primary buy-in" @click="buy(key)">{{ $t('coin_treasure.subscription') }}</button>
                                    <button v-if="item.status === 2" class="btn btn-primary disable" disabled="true">{{ $t('coin_treasure.sold_Out') }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="table mobile-table">
                        <div class="body">
                            <div class="card-item" v-for="(item, key) in list" :key="key">
                                <div>
                                    <div>
                                        <!-- <Coin class="coin" :name="item.token" /> -->
                                        {{ item.token }}
                                    </div>
                                    <span class="rates">
                                        <span class="label">{{ $t('coin_treasure.APR') }}</span>
                                        <span :class="item.interestRatesList[0].value > 0 ? 'up' : 'down'">
                                            {{ Math.round(item.value * 10000) / 100 }}%
                                        </span>
                                    </span>
                                </div>
                                <div></div>
                                <div class="flex-column">
                                    <div class="mb-2 label">{{ $t('coin_treasure.term_days') }}</div>
                                    <div class="day-list">
                                        <div v-for="(i, k) in item.interestRatesList" :key="k" class="day-li" :class="{ 'active': i.active === true }" @click="selectDay(key, k)">
                                            <span class="text">{{ i.day == '0' ? $t('coin_treasure.current') : i.day }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <!-- <span></span> -->
                                    <button v-if="item.status === 1" class="btn btn-primary flex-fill" @click="buy(key)">{{ $t('coin_treasure.subscription') }}</button>
                                    <button v-if="item.status === 2" class="btn btn-primary flex-fill disable" disabled="true">{{ $t('coin_treasure.sold_Out') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="loading-box" v-if="isLoadCurrentList">
                        <loading-indicator v-if="list.length === 0" />
                    </div>
                    <div class="col" v-if="!isLoadCurrentList && list.length == 0">
                        <div class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
                    </div>
                </div>
            </div>
            <div v-if="isShow">
                <Dialog ref="earnModal" :selectRowData="selectRowData" :balance="balance" @updateAmountValue="getSavingOrder"></Dialog>
            </div>

        </div>
    </div>
</template>

<script>

// import Coin from '../Components/Coin.vue';
import Dialog from './Components/EarnDialog.vue';
import { setProfile, getAppContext } from '@/assets/js/helper';
import { getSaving, savingOrders } from './request'
export default {
    components: { Dialog },
    data() {
        return {
            list: [],
            selectRowData: {},
            isShow: false,
            amount: 0,
            id: null,
            balance: NaN,
            isLoadCurrentList: true,
            amountValue: '--',
            interestValue: '--',
            lastInterestValue: '--',
            regionT: 'assets.assets_deposits',
        };
    },
    created() {
        getSaving().then(res => {
            this.isLoadCurrentList = true
            if (res.data.errcode !== 0) {
                return false
            }
            let data = res.data.data
            //格式化数据
            data.forEach(item => {
                let arr = []
                const { interestRates } = item
                // 利息格式化成数组用于渲染
                for (let i in interestRates) {
                    arr.push({ day: i, value: interestRates[i], active: false })
                }
                arr[0].active = true
                item.value = arr[0].value
                item.interestRatesList = arr
            })

            this.list = data
        })
    },
    mounted() {
        this.getBalance();
        this.getSavingOrder();
    },

    methods: {
        buy(key) {
            // 获取选中row data
            this.selectRowData = this.list[key]
            this.getBalance()
            // 重新挂载组件
            this.isShow = true
            this.$nextTick(() => {
                this.showDialog()
            })
        },
        selectDay(key, k) {
            // console.log(key, k);
            let selectData = this.list[key]
            selectData.interestRatesList.forEach(item => {
                item.active = false
            })
            selectData.interestRatesList[k].active = true
            selectData.value = selectData.interestRatesList[k].value
            this.$set(this.list, key, selectData)
            this.buy(key);
        },
        showDialog: function () {
            const modal = this.$refs.earnModal
            modal.showModal();

            //格式化传值
            // modal.formatData()
        },
        hideModel() {
            this.isShow = false
        },
        async getBalance() {
            if (isNaN(this.balance)) {
                const resp = await $.callPostApi(this, '/api/v1/balance/get?currency=USDT');
                this.balance = resp ? resp.balance + resp.frozen : 0;
            }
        },
        getSavingOrder() {
            savingOrders().then(res => {
                // 取消loading
                this.isLoadCurrentList = false
                if (res.data.errcode !== 0) {
                    return false
                }
                let data = res.data.data
                let amountValue = 0
                let interestValue = 0
                let lastInterestValue = 0
                data.forEach(item => {
                    if (item.status === 1 || item.status === 2) {
                        amountValue += item.amountValue
                    }
                    interestValue += item.interestValue
                    lastInterestValue += item.lastInterestValue
                })
                this.amountValue = amountValue.toFixed(4)
                this.interestValue = interestValue.toFixed(4)
                this.lastInterestValue = lastInterestValue.toFixed(4)
            })
        },
    },
};
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>