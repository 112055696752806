<template>
   <section>
     <section class="section1">
        <div class="container">
          <div class="title"> {{ $t("home.home_advantage",{platform:sysconfig.name}) }}</div> 
          <div class="content">
            <div class="text1">
                <div>{{ $t("home.home_advantage_title1") }}</div>
                <div>{{ $t("home.home_advantage_desp1") }}</div>
            </div>
            <div class="text2 mg">
                <div class="context">
                    <div>{{ $t("home.home_advantage_title2") }}</div>
                    <div>{{ $t("home.home_advantage_desp2") }}</div>
                </div> 
                <img src="@/assets/images/id-syip/photo.png"  /> 
            </div>
            <div class="text3">
                <div>{{ $t("home.home_advantage_title3") }}</div>
                <div>{{ $t("home.home_advantage_desp3") }}</div>
            </div>
            <div class="text2 lg">
                <div class="context">
                    <div>{{ $t("home.home_advantage_title2") }}</div>
                    <div>{{ $t("home.home_advantage_desp2") }}</div>
                </div> 
                <img src="@/assets/images/id-syip/photo.png"  /> 
            </div>
          </div>
        </div>         
     </section>
     <section class="section2">
        <div class="bottom container">
            <div class="left">
               <img src="@/assets/images/id-syip/cellphone.png"  />  
            </div>
            <div class="right">
                <div class="one">{{ $t("home.home_trade_title1") }}</div>
                <div class="two">{{ $t("home.home_trade_desp1") }}</div>
                <div class="three">
                    <img src="@/assets/images/id-syip/arrow.png"  /> 
                </div>
                <div class="four">
                    <div class="four-left">
                       <div class="top">{{ $t("home.home_trade_title2") }}</div>
                       <div class="down">{{ $t("home.home_trade_desp2") }}</div> 
                    </div>
                    <div class="four-right">
                        <div class="top">{{ $t("home.home_trade_title3") }}</div>
                       <div class="down">{{ $t("home.home_trade_desp3") }}</div> 
                    </div>
                </div>
            </div>
        </div>         
    </section>
   </section>
</template>
<script>
export default {
    data() {
        return {
           
        }
    },
    created() { },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.section1{
    margin-top: 30px;
    .title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 22px;
        color: #000000;
        text-align: center;
    }
    .content{
        margin-top: 30px;
        display: flex;
        .text1,.text3{
            width: 100%;
            height: 260px;
            background: #F0F0F0;
        }
        .mg{
            display: block;
        }
        .lg{
            display: none;
        }
        .text2{
            width: 100%;
            margin-left: 5%;
            margin-right: 5%;
            min-height: 600px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .context{
                width: 82%;
                height: 100%;
                background: #003F16;
                padding: 20px;
                >div{
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: #fff;
                    margin-bottom: 10px;
                }
                >div:nth-child(1){
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    font-size: 20px;
                    color: #fff;
                }
            }
            img{
                width: 100%;
                height: 50%;
                position: absolute;
                top:50%;
                right: 0;
                left: 0;
                bottom: 30px;
                margin: auto;
            }
        }
        .text1,.text3{
            padding: 20px;
            >div{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000;
                margin-bottom: 10px;
            }
            >div:nth-child(1){
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 20px;
                color: #000000;
            }
        }
    }
}
.section2{
    margin-top: 30px;
    margin-bottom: 20px;
    .bottom{
        width: 100%;
        display: flex;
        .left{
            width: 50%;
            img{
                width: 100%;
                height: auto;
            }
        }
        .right{
            width: 50%;
            background: #002C15;
            padding: 6% 20px 20px 40px;
            .one{
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                font-size: 20px;
                color: #FFFFFF;
                margin-bottom: 20px;
            }
            .two{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                margin-bottom: 20px;
            }
            .four{
                margin-top: 50px;
                display: flex;
                .four-left{
                    margin-right: 10px;
                }
                .top{
                    width: 100%;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 600;
                    font-size: 20px;
                    color: #FFFFFF;
                }
                .down{
                    width: 100%;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    font-size: 14px;
                    color: #FFFFFF;
                }
            }
        }
    }
}





@media (max-width: 992px) {
}
@media (max-width: 1024px)
{   
    .section1{
        .content{
             flex-direction: column;
            .text1,.text3{
                margin-bottom: 30px;
            }
            .mg{
                display: none;
            }
            .lg{
                display: block;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                height: 600px;
                position: relative;
                .context{
                    width: 300px;
                }
                img{
                    width: 330px;
                    height: 280px;
                    position: absolute;
                    top:50%;
                    right: 0;
                    left: 0;
                    bottom: 30px;
                    margin: auto;
                }
            }
        }
    }
    .section2{
        .bottom{
            flex-direction: column;
            .left,.right{
                width: 100%;
            }
            .right{
                .three{
                    text-align: center;
                    margin-top: 50px;
                }
            }
        }
    }
}
@media (min-width: 1024px)
{
      .section1{
        .content{
            .mg{
                display: flex;
            }
            .lg{
                display: none;
            }
        }
    }
}
@media (max-width: 768px) {
    .section2{
        .bottom{
            .left{
                height: 500px;
            }
            .right{
                height: 600px;
            }
        }
    }
}
</style>
