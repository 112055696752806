<template>
    <section class="page login-page layout-login d-flex">
        <div class="d-flex flex-row login-left-right flex-fill">
            <div class="login-page-right login-container">
                <div class="login-box">
                    <div class="login container ">
                        <div class="login-inner" id="login-page">
                            <loading-indicator v-if="!config" />
                            <template v-else>
                                <create-account-component v-if="config.signupEnabled === true" :config="config" :regions="regions" :referral="id" />
                                <div class="container" v-else>
                                    <div class="row">
                                        <div class="col">
                                            <div class="alert alert-info mt-5">
                                                <h1>{{ $t('login.system_update') }}</h1>
                                                <p>{{ $t('login.system_update_des') }}</p>
                                                <hr />
                                                <router-link to="/user/login">{{ $t('login.title') }}</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped lang="less">
.login-left-right {
  margin: auto;
  width: 100%;
}
.login-container {
  background: linear-gradient( 297deg, #002C15 0%, #184627 92%);
  padding: 0 70px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.login-container .login-box {
  background: none;
  width: auto;
  min-width: 45%;
  padding-left: 0;
  position: relative;
}
.login-container .img .tips {
  width: 100%;
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 10px;
  color: #FEFFFF;
  line-height: 10px;
  text-align: center;
  font-style: normal;
  background-color:initial;
  margin: 0;
  padding: 0 10px;
  line-height: 16px;
}
.login-container .img .title{
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
  padding: 0 15px;
  margin: 15px;
}
.login-box .login {
  background-color: #fff;
  border-radius: 38px 38px 38px 38px;
  border: 2px solid rgb(196, 246, 62);
}
.img {
  width: 50%;
  margin: 0;
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.login-container > .img img {
  max-width: 540px;
  min-width: 450px;
  height: auto;
  flex: 1;
}
#login-page {
  padding-top: 30px;
}
@media (max-width: 1024px) {
  .login-box .container {
      margin: 0 auto;
  }
  .login-container .img {
      display: none !important;
  }

  .login-container .login-box {
      width: 100%;
  }
  .login-page .login h1 {
      color: #000;
  }
  .img{
      display: none;
  }
}
@media (max-width: 768px) {
  .login-container {
      padding: 0;
      display: block;
  }
  .login-container .login-box {
      padding: 0;
      border-radius: 30px;
      height: 100%;
      justify-content: center;
      align-items: center;
  }
  .login-box .container {
      min-width: auto;
      margin: 0;
      width: 100%;
      padding: 0;
  }
  .login {
      position: static;
      transform: none;
      background: none;
  }
  .login-box .login {
      background: none;
      border-radius: 0;
      border: none;
  }
  #login-page {
      margin: 0;
      border-radius: 0;
      padding: 30px 40px;
      max-width: none;
  }
}
</style>

<script>
import { getConfigAsync } from 'utilities/helper';
import CreateAccountComponent from './Components/CreateAccount.vue';

export default {
    components: { CreateAccountComponent },
    props: ['id'],

    data() {
        return {
            config: null,

            // All supported regions
            regions: null
        };
    },

    created() {
        this.initAsync();
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    methods: {
        initAsync: async function () {
            const self = this;

            let config = await getConfigAsync();
            let resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp) {
                self.regions = resp.data || [];
                self.config = config;
            }
        },

        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    }
};
</script>