<template>
    <form class="login-form" method="post" action="javascript:void(0)" v-on:submit="do_login">
        <input type="hidden" name="__RequestVerificationToken" v-bind:value="csrfToken" />
        <div class="page-top-nav mb-3" v-if="sysconfig.phoneSupported && sysconfig.emailSupported">
            <li :class="{ active: !emailVCode }">
                <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
            </li>
            <li :class="{ active: emailVCode }">
                <a @click="emailVCode = true" href="javascript:;">{{ $t('register.label_email') }}</a>
            </li>
        </div>
        <div class="form-group">

            <template v-if="emailVCode">
                <input type="hidden" name="RegionCode" value="+1" />
                <input name="UserName" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
            </template>
            <div v-else class="d-flex">
                <region-selector v-bind:regions="regions" />
                <div class="flex-fill">
                    <input name="UserName" v-model.trim="UserName"  data-val="true" data-val-required="*" pattern="\d*" type="text" value="" class="form-control round-right phone" :placeholder="$t('login.account')" />
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="password-box">
                <input name="Password" :type="showPassword?'text':'password'" v-model.trim="Password" autocomplete="off" data-val="true" data-val-required="*" class="form-control" :placeholder="$t('login.password')" />
                <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <!-- Google Authenticator Code -->
        <div class="form-group">
            <input name="GAPin" v-model.trim="GAPin" type="text" autocomplete="off" maxlength="6" pattern="\d*" data-val-regex="*" data-val-regex-pattern="^\d{6}$" class="form-control" :placeholder="$t('login.ga_pin')" />
            <div class="help-block">{{ $t('login.ga_pin_desp') }}</div>
        </div>
        <div class="form-group">
            <submit-button ref="submitButton" :text="$t('login.login_button')" />
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col">
                    <router-link to="/user/reset" class="help-link">{{ $t('login.forgot_password') }}</router-link>
                </div>
                <!-- Do we need to display the registration link? If could be configured on the server. -->
                <div class="col text-right">
                    <router-link to="/user/create" class="help-link">{{ $t('login.free_register') }}</router-link>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';

export default {
    components: { RegionSelector },

    props: ['regions', 'csrfToken'],

    created() {
        const regions = this.regions;
        if (!regions || !Array.isArray(regions)) throw new Error('An array of supported regions is required.');

        const token = this.csrfToken;
        if (!token || typeof token !== 'string') throw new Error('A valid CSRF token is required.');

        if (this.supportMultipleRegions) {
            this.regionCode = regions[0].areaCode;
        }
    },

    data() {
        return { 
          regionCode: null,
          emailVCode: false,
          profile: null,
          showPassword:false,
          Password:null,
          UserName:null,
          GAPin:null
        };
    },
     watch: {
        emailVCode: function () {
            Vue.nextTick(() => {
                $.resetValidators();
            });
        }
    },

    computed: {
        supportMultipleRegions: function () {
            const regions = this.regions;
            return regions != null && regions.length > 0;
        }
    },

    mounted() {
        $.resetValidators();
    },

   methods: {
        do_login: function (e) {
            const self = this;
            const frm = $(e.target);
            if (frm.valid()) {
                self.$refs.submitButton.submit();

                const _on_submit_error = function (err) {
                    const _callback = function () {
                        $.top_error(err || self.$t('login.login_failed'));
                        self.$refs.submitButton.reset();
                    };
                    setTimeout(_callback, 5000);
                };

                self.$http
                    .post('/api/v1/account/signin', frm.serialize(), { headers: { 'X-XSRF-TOKEN': self.csrfToken } })
                    .then((resp) => {
                        const json = resp.data;

                        if (json && json.errcode == 0) {
                            // Save the user profile.
                            setProfile(json.data);

                            self.$refs.submitButton.complete();
                            setTimeout(function () {
                                // Jump to the real-name authentication page without real-name
                                if (json.data.verifiedLevel < json.data.requiredIDLevel){
                                    self.$router.push('/user/verifyid');
                                } else {
                                    self.getBindBankStatus()
                                }
                            }, 200);
                        } else if (json && json.errcode == 104){
                            _on_submit_error(self.$t('login.login_ga_err'));
                        } else {
                            _on_submit_error(json.errmsg);
                        }
                    })
                    .catch((err) => {
                        console.log('Login failed' + JSON.stringify(err));
                        _on_submit_error();
                    });
            }
        },

        async getBindBankStatus(){
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0 && json.data.length === 0) {
                this.$router.push('/user/bankaccount');
            } else {
                // Redirect to user home if not specified.
                const from = $.trim(this.$route.query.from);
                if(from.length && from.startsWith('/')) {
                    this.$router.push(from);
                } else {
                    this.$router.push('/user');
                }
            }
        }
    }
};
</script>
<style type="text/css" scoped>
.page-top-nav{
    display: flex;
    margin-bottom: 2rem !important;
    align-items: center;
    justify-content: center;
}
.page-top-nav li{
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}
.page-top-nav li.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 4.25rem;
    height: .125rem;
    background-color: #041438;
}
.page-top-nav li a {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    height: 100%;
}
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
#login-page button.btn-submit {
    margin: 1rem 0;
    display: block;
    width: 100%;
    padding: .75rem 0;
    color: #000000;
    background: #C7F646;
    font-weight: 600;
    border-radius: 234px 234px 234px 234px;
}
.help-block {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #7F8490;
    font-size: 14px;
    padding-left: 1.3rem;
}
.form-group {
    margin-bottom: 1.5625rem;
}
.help-link {
    font-size: 12px;
    color: #7F8490;
}
@media (max-width: 768px) {
    .page-top-nav li.active a {
        color: #ffffff;
    }
}
</style>